import dayjs from 'dayjs';

export const specialDiscountsMixin = {
    data() {
        return {
            loading: false,
            shipsOptions: [
                {
                    id: 0,
                    name: 'Все теплоходы',
                    options: [
                        {
                            id: 1,
                            name: 'А. Матросов',
                        },
                        {
                            id: 2,
                            name: 'А.И. Герцен',
                        },
                        {
                            id: 3,
                            name: 'Зосима Шашков',
                        },
                        {
                            id: 4,
                            name: 'Белая Русь',
                        },
                    ],
                },
            ],
            formData: {
                name: null,
                amount: null,
                date_from: null,
                date_to: null,
                active: false,
            },
        };
    },
    methods: {
        mapFormData() {
            return {
                ...this.formData,
                date_from: this.formData.date_from ? new Date(this.formData.date_from) : null,
                date_to: this.formData.date_to ? new Date(this.formData.date_to) : null,
            };
        },
        async fetchCreateParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/special-discounts/create`);

                this.shipsOptions = [
                    {
                        id: 0,
                        name: 'Все теплоходы',
                        options: data.data.ships_options,
                    },
                ];

                this.loading = false;
            } catch (ex) {
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
        async fetchEditParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/special-discounts/${this.$route.params.id}/edit`);

                // заполняем данные
                for (const [key, val] of Object.entries(data.data)) {
                    this.formData[key] = val;
                }

                const response = { ...data.data };

                this.formData.date_from = response.date_from
                    ? dayjs(response.date_from).format('YYYY-MM-DDTHH:mm:ss')
                    : null;
                this.formData.date_to = response.date_to ? dayjs(response.date_to).format('YYYY-MM-DDTHH:mm:ss') : null;
            } catch (ex) {
                console.log('cannot fetch special discount edit params ' + ex);
                this.$router.push('/error-page');
            } finally {
                this.loading = false;
            }
        },
    },
};
