<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <form
                method="post"
                class="grid grid-cols-12 gap-6"
                :class="{ 'disabled-form': loading }"
                @submit.prevent="save"
            >
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-4">
                    <label for="form-name" class="form-label">
                        Название
                        <sup v-if="v$.formData.name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.formData.name.$errors.length" for="form-name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.formData.name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="formData.name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.formData.name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-2 flex items-center pt-6">
                    <input
                        id="form-active"
                        v-model="formData.active"
                        class="show-code form-check-switch flex-none mr-0"
                        type="checkbox"
                    />
                    <label for="form-published" class="form-check-label">Активно</label>
                </div>

                <div class="col-span-6">
                    <label for="form-ships" class="form-label">Теплоходы</label>
                    <Multiselect
                        id="form-ships"
                        v-model="formData.ships"
                        value-prop="id"
                        placeholder="Выберите теплоходы"
                        group-label="name"
                        track-by="name"
                        label="name"
                        :groups="true"
                        mode="multiple"
                        :options="shipsOptions"
                        :close-on-select="false"
                        :hide-selected="false"
                    />
                </div>

                <div class="col-span-12 grid grid-cols-12 gap-6">
                    <div class="col-span-6 grid grid-cols-12 gap-3">
                        <div class="relative col-span-8">
                            <label for="form-decks_number_int" class="form-label">
                                Размер скидки в процентах
                                <sup v-if="v$.formData.amount.required" class="text-theme-6">*</sup>
                            </label>
                            <label
                                v-if="v$.formData.amount.$errors.length"
                                for="form-decks_number_int"
                                class="form-input-text-error"
                            >
                                {{ getOverrideMessage(v$.formData.amount.$errors[0].$message) }}
                            </label>
                            <input
                                id="form-discount_amount"
                                v-model.number="formData.amount"
                                type="number"
                                min="0"
                                class="form-control w-full"
                                :class="{ 'form-input-error': v$.formData.amount.$errors.length }"
                                placeholder=""
                                autocomplete="off"
                            />
                        </div>
                    </div>
                    <div class="col-span-6 grid grid-cols-12 gap-3">
                        <div class="col-span-6">
                            <label for="form-cruise_date" class="form-label">Дата начала</label>
                            <input
                                id="form-from"
                                v-model="formData.date_from"
                                type="datetime-local"
                                class="form-control w-full"
                                autocomplete="off"
                            />
                        </div>

                        <div class="col-span-6">
                            <label for="form-cruise_date" class="form-label">Дата конца</label>
                            <input
                                id="form-to"
                                v-model="formData.date_to"
                                type="datetime-local"
                                class="form-control w-full"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'special-discounts' }" class="btn btn-secondary w-24 mr-2 mb-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(true)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save()">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import { specialDiscountsMixin } from '@/mixins/form/special-discounts-mixin';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';
import Multiselect from '@vueform/multiselect';

export default {
    name: 'Edit',
    components: { Multiselect, Preloader },
    mixins: [errorResponse, specialDiscountsMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Редактирование специальной скидки');
        await Promise.all([this.fetchCreateParams(), this.fetchEditParams()]);
    },
    validations() {
        return {
            formData: {
                name: { required },
                amount: { required },
            },
        };
    },
    methods: {
        prepareFormData(form) {
            const preparedFormData = JSON.parse(JSON.stringify(form));

            return preparedFormData;
        },
        async save(redirect = false) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                this.form = this.mapFormData();

                const formData = formPreparation(this.prepareFormData(this.form));
                const { data } = await this.axios.post('/special-discounts/' + this.$route.params.id, formData, {
                    params: { _method: 'patch' },
                });

                this.$notify(data.message || 'Специальная скидка обновлена');

                if (redirect) {
                    await this.$router.push({ name: 'special-discounts' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            } finally {
                this.loading = false;
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
